import Vue from 'vue'
import VueRouter from 'vue-router'
// import Index from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },{
    path: '/messageindex',
    name: 'messageIndex',
    component: () => import('../views/messages/messageIndex.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('../views/layout.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页'
        },
        component: () => import('../views/index/index.vue')
      },
      {
        path: '/explain',
        name: 'explain',
        meta: {
          title: '语音讲解'
        },
        component: () => import('../views/explain/explain.vue'),
      },
      {
        path: '/empowerment',
        name: 'empowerment',
        meta: {
          title: '数字赋能'
        },
        component: () => import('../views/empowerment/empowerment.vue'),
        //  redirect:'/empowerment/tree',
        children: [
          {
            path: '/empowerment/tree',
            name: 'tree',
            meta: {
              title: '古树芯'
            },
            component: () => import('../views/empowerment/tree.vue')
          },
          {
            path: '/empowerment/root',
            name: 'root',
            meta: {
              title: '根系就数字化'
            },
            component: () => import('../views/empowerment/root.vue')
          },
          {
            path: '/empowerment/diseases',
            name: 'diseases',
            meta: {
              title: '病虫测控无人化'
            },
            component: () => import('../views/empowerment/diseases.vue')
          },
          {
            path: '/empowerment/tree_hole',
            name: 'tree_hole',
            meta: {
              title: '树洞智能化'
            },
            component: () => import('../views/empowerment/tree_hole.vue')
          },
          {
            path: '/empowerment/tree_age',
            name: 'tree_age',
            meta: {
              title: '树龄智慧化'
            },
            component: () => import('../views/empowerment/tree_age.vue')
          },
          {
            path: '/empowerment/tree_vr',
            name: 'tree_vr',
            meta: {
              title: 'VR人性化',
              // keepAlive:true
            },
            component: () => import('../views/empowerment/tree_vr.vue')
          },]
      },
      {
        path: '/culture',
        name: 'culture',
        meta: {
          title: '古树文化'
        },
        component: () => import('../views/culture/culture.vue'),
        //  redirect:'/culture/story',
        children: [
          {
            path: '/culture/story',
            name: 'story',
            meta: {
              title: '古树故事'
            },
            component: () => import('../views/culture/story.vue')
          },
          {
            path: '/culture/history',
            name: 'history',
            meta: {
              title: '历史文化'
            },
            component: () => import('../views/culture/history.vue')
          },
          {
            path: '/culture/nos',
            name: 'nos',
            meta: {
              title: '乡愁情怀'
            },
            component: () => import('../views/culture/nos.vue')
          },
        ]
      },
      {
        path: '/rural',
        name: 'rural',
        meta: {
          title: '乡村振兴'
        },
        component: () => import('../views/rural/rural.vue'),
        children: [
          {
            path: '/rural/park',
            name: 'park',
            meta: {
              title: '古树公园'
            },
            component: () => import('../views/rural/park.vue')
          },
          {
            path: '/rural/travel',
            name: 'park',
            meta: {
              title: '古树旅游'
            },
            component: () => import('../views/rural/travel.vue')
          },
          {
            path: '/rural/derivative',
            name: 'park',
            meta: {
              title: '古树衍生品'
            },
            component: () => import('../views/rural/derivative.vue')
          },
        ]
      },
      {
        path: '/success',
        name: 'success',
        meta: {
          title: '成功案例'
        },
        component: () => import('../views/success/success.vue'),
        // redirect:'/success/experience',
        children: [
          {
            path: '/success/experience',
            name: 'experience',
            meta: {
              title: '古树体检'
            },
            component: () => import('../views/success/experience.vue')
          },
          {
            path: '/success/rejuvenation',
            name: 'rejuvenation',
            meta: {
              title: '古树复壮'
            },
            component: () => import('../views/success/rejuvenation.vue')
          },
          {
            path: '/success/rescue',
            name: 'rescue',
            meta: {
              title: '古树救护'
            },
            component: () => import('../views/success/rescue.vue')
          },
          {
            path: '/success/rescue_administration',
            name: 'rescue_administration',
            meta: {
              title: '养护管理'
            },
            component: () => import('../views/success/rescue_administration.vue')
          },

        ]
      },
      {
        path: '/encyclopedias',
        name: 'encyclopedias',
        meta: {
          title: '科普百科'
        },
        component: () => import('../views/encyclopedias/encyclopedias.vue')
      },
      {
        path: '/news',
        name: 'news',
        meta: {
          title: '新闻宣传'
        },
        component: () => import('../views/news/news.vue'),
        // redirect:'/news/industry',
        children: [
          {
            path: '/news/industry',
            name: 'industry',
            meta: {
              title: '行业关注'
            },
            component: () => import('../views/news/industry.vue')
          },
          {
            path: '/news/video',
            name: 'video',
            meta: {
              title: '宣传视频'
            },
            component: () => import('../views/news/video.vue')
          },
        ]
      },
      {
        path: '/service',
        name: 'service',
        meta: {
          title: '技术服务'
        },
        component: () => import('../views/service/service.vue'),
        children: [
          {
            path: '/service/technology',
            name: 'technology',
            meta: {
              title: '养护技术服务'
            },
            component: () => import('../views/service/technology.vue')
          },
          {
            path: '/service/material',
            name: 'material',
            meta: {
              title: '养护物资服务'
            },
            component: () => import('../views/service/material.vue')
          },
        ]
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        meta: {
          title: '行业动态'
        },
        component: () => import('../views/dynamic/dynamic.vue'),
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          title: '关于我们'
        },
        component: () => import('../views/about/about.vue'),
        // redirect:'/about/introduction',
        children: [
          {
            path: '/about/introduction',
            name: 'introduction',
            meta: {
              title: '公司简介'
            },
            component: () => import('../views/about/introduction.vue')
          },
          {
            path: '/about/work_dynamic',
            name: 'work_dynamic',
            meta: {
              title: '工作动态'
            },
            component: () => import('../views/about/work_dynamic.vue')
          },
          {
            path: '/about/contact',
            name: 'contact',
            meta: {
              title: '联系方式'
            },
            component: () => import('../views/about/contact.vue')
          },
        ]
      },
      {
        path: '/detail',
        name: 'detail',
        meta: {
          title: '详情'
        },
        component: () => import('../views/detail/detail.vue')
      },
      {
        path: '/servicedetail',
        name: 'servicedetail',
        meta: {
          title: '详情'
        },
        component: () => import('../views/detail/serviceform.vue')
      },
      {
        path: '/vr_detail',
        name: 'vr_detail',
        meta: {
          title: 'VR详情'
        },
        component: () => import('../views/detail/vr_detail.vue')
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)

})
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = '古树名木' + '-' + to.meta.title
  } else {
    document.title = '古树名木'
  }
  next()
})

export default router
