export default{
    setlog(state,data){
        state.log={
            title:data.name,
            src:'/gsmm/api/v1/'+data.logo_src
        }
        state.content=data.introduce
        state.contact={
            email:data.email,
            landline:data.landline,
            code_src:'/gsmm/api/v1/'+data.code_src,
            phone:data.phone,
            site:data.site
        }
    },
    setBanner(state,data){
            
            state.bannerList=data    
    },
    setShowBanner(state,data){
state.ShowBanner=data
    },
    setColumn(state,data){
        state.columnList=data
    },
    setimgList(state,data){
        state.imgList=data
        state.imgSrc='/gsmm/api/v1/'+data[0].url
    },
    setimgSrc(state,url){
        state.imgSrc='/gsmm/api/v1/'+url 
    },
    setplaceList(state,data){
        state.placeList=data
    },
    setplaceList1(state,data){
        state.placeList1=data
    },
    setplace(state,data){
        state.place=data
        state.show=false
        this.dispatch('handelNum')    
        state.org_id=data
        this.dispatch('handleBanners')
        this.dispatch('handleTotal')
        // this.dispatch('handelinformation')
        // console.log(state.place)
    },
    setTotal(state,data){
state.total=data
    },
    setNum(state,data){
        if(!data){
            state.numItem={
                one:0,
                two:0,
                three:0,
                tree:0
            }
        }else{
            state.numItem=data
        }
       
    },
    setinformationList(state,data){
        state.informationList=data.list
        state.informationTotal=data.total
    },
    setdetail(state,data){
        state.detail=data
    },
    setdetail1(state,data){
        state.successdetail=data

    },
    setShow(state,show){
        state.show=show
    },
    setiSuccessLis(state,data){
        state.successList=data.list
        state.successTotal=data.total
    },
    setPath(state,data){
        state.path=data
    },
     setXuan(state,data){
        state.xuan=data
    }

}