export function  listToTree(list,parent_id=null) {//list为数组，pid为第一级的区分标准
          return list
            .filter(item => item.parent_id===parent_id)
            .map(item => {
            // let abc=this.commit('listToTree', list,item.id)
               let abc=listToTree(list, item.id)//判断有没有子级，有子级加children,无子级不加children
               if(abc.length>0){
                 return {//key和title是为了适应ant-design-vue的多级嵌套表格，可去除
                   ...item,value: item.id,label: item.name, children: abc
                 }
               }else{
                  return {
                   ...item,value: item.id,label: item.name
                 }
               }
            })
    }