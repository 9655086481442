import request from '../utils/request'
import {listToTree} from './util'

export default {
    async handlelog({commit}) {
        const res = await request('/about/show')
        if (res.status == 200) {
            commit('setlog', res.data)
        }
    },
    async handleBanners({commit, state}) {
        commit('setShowBanner', false)
        const res = await request('/img/show', {
            params: {
                imgType: 'BANNER',
                orgId: state.org_id ? state.org_id : '007'
            }
        })
        if (res.status == 200) {
            console.log(res.data.list);
            commit('setBanner', res.data.list)
            commit('setShowBanner', true)

        }
    },
    async handleColumn({commit}, id) {
        const res = await request('/column/info', {params: {id}})
        if (res.status == 200) {
            commit('setColumn', res.data)
        }
    },
    async handleimgList({commit}, id) {
        const res = await request('/column/info', {params: {id}})
        if (res.status == 200) {
            commit('setimgList', res.data.img_info_list)
        }
    },
    async handleplaceList({commit}) {
        // console.log(id)
        const res = await request('/domain/all')
        if (res.status == 200) {
            commit('setplaceList1', res.data)
            commit('setplaceList', listToTree(res.data))
        }
    },
    async handelNum({commit, state}) {
        const res = await request('tree/num', {params: {orgId: state.place}})
        commit('setShow', false)
        if (res.status == 200) {
            commit('setNum', res.data)
            commit('setShow', true)
        }
    },
    async handelinformation({commit, state}, form) {
        const res = await request('/introduce/show', {
            params: {
                ...form,
                orgId: state.place
            }
        })
        if (res.status == 200) {
            commit('setinformationList', res.data)
        }
    },
    async handelinformation1({commit, state}, form) {
        const res = await request('/introduce/show', {params: form})
        if (res.status == 200) {
            commit('setinformationList', res.data)
        }
    },
    async handleDetail({commit}, id) {
        const res = await request('/introduce/show', {
            params: {
                id
            }
        })
        if (res.status == 200) {
            commit('setdetail', res.data.list[0])
        }
    },
    async handleDetail1({commit}, id) {
        const res = await request('/case/show', {
            params: {
                id
            }
        })
        console.log(res)
        if (res.status == 200) {
            commit('setdetail1', res.data.list[0])
        }
    },
    async handelSuccessList({commit, state}, form) {
        const res = await request('/case/show', {
            params: {
                ...form,
                orgId: state.place
            }
        })
        console.log(res)
        if (res.status == 200) {
            commit('setiSuccessLis', res.data)
        }
    },
    async handleTotal({commit, state}) {
        const res = await request('/introduce/show',
            {
                params: {
                    type: 'DEVOTC',
                    pageNum: 1,
                    pageSize: 6,
                    orgId: state.place
                }
            })
        if (res.status == 200) {
            commit('setTotal', res.data.total)
        }
    }
}
