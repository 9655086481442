<template>
	<div class="menu">
		<div
			class="eachMenu"
			v-for="(menu, index) in menuList"
			:key="index"
			@click="selectCurMenu(menu, index)"
			@mouseover="openDownMenu(menu, index)"
			@mouseout="closeDownMenu"
			:style="{'cursor':menu.children?'':'pointer'}"
		>
			<div class="menuList">
				<img src="../../assets/menu.png" />
				<div class="br1">
					<div class="br" v-if="index == 0"></div>
					<div class="text">
						<span :class="[xuan == menu.path ? 'index' : '']">{{ menu.name }}</span>
					</div>
					<div class="br"></div>
				</div>
			</div>
			<div class="downMenu" v-if="menu.children" :style="curHoverIndex == index ? CurHeight : ''">
				<div
					v-for="(downMenu, indexDown) in menu.children"
					class="eachDownMenu"
					:key="indexDown"
					@click="chooseCurDownMenu(downMenu, index)"
				>
					<span :class="[path == downMenu.path ? 'active' : '']">{{ downMenu.name }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			menuList: [],
			curHoverIndex: -1,
			CurHeight: '',
			// xuan: ''
		}
	},
	computed:{
		...mapState(['xuan','path'])
	},
	created() {
		const a = this.$route.path.split('/')
		this.$store.commit('setXuan', '/' + a[1])
		this.$store.commit('setPath',this.$route.path)
		// this.xuan = '/' + a[1]
	},
	mounted(){
		this.handleMenu()
	},
	methods: {
			async handleMenu(){
			const res=await this.request('/column/show',{params:{isShow:true}})
			// console.log(res)
			if(res.status==200){
				this.menuList=this.listToTree(res.data)
				// console.log(this.menuList)
			}
		},
		listToTree(list, pid = 0){//list为数组，pid为第一级的区分标准
      return list
        .filter(item => item.pid === pid)
                .sort((a,b)=>a.sort - b.sort)
    .map(item=>{
       let abc=this.listToTree(list, item.id)//判断有没有子级，有子级加children,无子级不加children
       if(abc.length>0){
         return {//key和title是为了适应ant-design-vue的多级嵌套表格，可去除
           ...item, children: abc
         }
       }else{
          return {
           ...item, 
         }
       }
    })
},
		chooseCurDownMenu(downMenu, index) {
			console.log(downMenu.path)
			this.$router.push(downMenu.path)
			const a = downMenu.path.split('/')
			this.$store.commit('setXuan', '/' + a[1])
			this.$store.commit('setPath',downMenu.path)
			// this.xuan = '/' + a[1]
		},
		closeDownMenu() {
			this.curHoverIndex = -1
		},
		selectCurMenu(menu, index) {
console.log(menu)

			if (!menu.children) {
				if(menu.name!=='综合管理'){
					this.$router.push(menu.path)
				const a = menu.path.split('/')
				this.$store.commit('setXuan', '/' + a[1])
				this.$store.commit('setPath',menu.path)
				// this.xuan = '/' + a[1]
				}else{
					window.open('http://gs.xcforest.com/')
				}

			}else{
				// 	this.$router.push(menu.children[0].path)

				// this.xuan = menu.path
			}
		},
		openDownMenu(item, index) {
			this.curHoverIndex = index
			if (item.children) {
				let num = 36 * item.children.length
				this.CurHeight = 'width:' + num + 'px'
			}
			// console.log(this.CurHeight)
		},
	}
}
</script>

<style scoped="scoped" lang="less">
.menu {
	display: flex;
	font-size: 14px;
	line-height: 1.2em;

	.eachMenu {
		position: relative;
		color: #960;
		.menuList {
			//一级菜单样式
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			img {
				width: 9px;
				height: 9px;
				margin: 3px 0;
			}

			width: 50px;

			.text {
				width: 100%;
				padding: 0 15px;
				// background-color: yellow;
				.index {
					//一级选中的样式
					background-color: #960;
					color: #fff;
					line-height: 1.2em;
				}
				span:hover {
				    background:#960;
			      	color:#fff;
			    }
			}


			.br1 {
				display: flex;
				.br {
					width: 0;
					height: 30px;
					border-left: 1px solid #960;
				}
			}
		}

		.downMenu {
			height: auto;
			width: 0;
			position: absolute;
			left: 50%;
			transform: translate(-50%);
			z-index: 16;
			top: 100%;
			background-color: #fff;
			border-radius: 4px;
			transition: width 0.3s;
			overflow: hidden;
			// white-space: nowrap;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
			display: flex;
			// padding: 20px 0;
			.eachDownMenu {
				width: 36px;
				color: #960;
				padding: 5px 8px;
				line-height: 1.2em;
				cursor: pointer;
				.active {
					background-color: #960;
					color: #fff;
					// line-height: 1.2em;
				}
				span:hover{
					background:#960;
					color:#fff
				}
			}
		}
	}
}
</style>
