import axios from 'axios'
// 使用element-ui Message做消息提醒
//  import { Message} from 'element-ui';
// import store from "@/store";
// import {message} from "ant-design-vue";
//1. 创建新的axios实例，
let baseUrl = ""

if(process.env.NODE_ENV !== "production"){
    baseUrl = "/gsmm/api/v1/"
}else{
    baseUrl = "/gsmm/api/v1/"
}
console.log(baseUrl)
const service = axios.create({
    baseURL:baseUrl,
    // timeout:3000
})
// 2.请求拦截器
service.interceptors.request.use(config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    // config.headers = {
    //     'Content-Type':'application/x-www-form-urlencoded' //配置请求头
    // }
    //判断请求 token
    if (config.method === 'put' && config.url === '/auth/token') {
        config.headers.Authorization = sessionStorage.getItem('refresh_token')
    } else if (config.method !== 'post' || config.url !== '/auth/token') {
        config.headers.Authorization = sessionStorage.getItem('access_token')
    }
//     console.log(config)

    //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    // const access_token = localStorage.getItem('access_token');//这里取token之前，你肯定需要先拿到token,存一下
    return config
}, error => {
    Promise.reject(error)
})
// 3.响应拦截器
service.interceptors.response.use(async response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    const res = response.data
//      if(!res){//无返回值
//         let res = await service.put('/auth/token',{})
//         if(res.ok){
//             sessionStorage.setItem('refresh_token',res.data.refresh_token)
//             sessionStorage.setItem('access_token','Bearer '+res.data.access_token)
//             
//             return await service.request(response.config)
//         }else {
//             message.error('长时间未操作，返回登录页!')
//             window.location.href = "/"
//         }
//     }else

    if (!res.ok && res.state === 401) {//登录过期
        let res = await service.put('/auth/token', {})
        if (res.ok) {
            sessionStorage.setItem('refresh_token', res.data.refresh_token)
            sessionStorage.setItem('access_token', 'Bearer ' + res.data.access_token)

            return await service.request(response.config)
        } else {
            //  Message.error('长时间未操作，返回登录页!')
            window.location.href = "/"
        }
    } else if (!res.ok) {
//         console.log(res)
//  Message.error(res.message)
    }
    return res
}, error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
//         switch (error.response.status) {
//             case 400:
//                 error.message = '错误请求'
//                 break;
//             case 401:
//                 error.message = '未授权，请重新登录'
//                 break;
//             case 403:
//                 error.message = '拒绝访问'
//                 break;
//             case 404:
//                 error.message = '请求错误,未找到该资源'
//                 // window.location.href = "/NotFound"
//                 break;
//             case 405:
//                 error.message = '请求方法未允许'
//                 break;
//             case 408:
//                 error.message = '请求超时'
//                 break;
//             case 500:
//                 error.message = '服务器端出错'
//                 break;
//             case 501:
//                 error.message = '网络未实现'
//                 break;
//             case 502:
//                 error.message = '网络错误'
//                 break;
//             case 503:
//                 error.message = '服务不可用'
//                 break;
//             case 504:
//                 error.message = '网络超时'
//                 break;
//             case 505:
//                 error.message = 'http版本不支持该请求'
//                 break;
//             default:
//                 error.message = `连接错误${error.response.status}`
//         }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            //  Message.error('服务器响应超时，请刷新当前页')
        }
//         error.message('连接服务器失败')
    }
//     console.log(error.message)
//     Message.error(error.message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
})
//4.导入文件
export default service
