export default {
    log: {},//头部
    content: '',//公司简介
    org_id: '007',
    bannerList: [],//轮播图
    contact: {},//联系方式
    columnList: {},//一级菜单详情
    imgList: [],//二级菜单图片数组，
    imgSrc: '',//二级图片
    placeList: [],//区域列表
    placeList1: [],
    place: '',//选中
    numItem: {
        one: 0,
        two: 0,
        three: 0,
        tree: 0
    },//古树级别
    informationList: [],//信息列表
    informationTotal: 0,//信息数量,
    detail: {},//详情
    show: false,
    successList: [],//成功案例
    successTotal: 0,//成功案例数量
    successdetail: {},//成功案例详情
    xuan: '',
    path: '',
    total: null,
    ShowBanner: false
}
