<template>
    <div class="footer">
        <div class="left">
           <div class="address">
               <div class="title">公司地址</div>
               <div class="content">
                   {{item.company_loc}}
               </div>
           </div>
            <div class="bian"></div>
           <div class="fuwu">
                <div class="title">服务中心</div>
               <div class="content">
                   <p>电话：{{item.serve_tel}}</p>
                   <p>服务热线：{{item.serve_phone_number}}</p>
               </div>
           </div>
            <div class="bian"></div>
           <div class="pingtai">
               <div class="title">企业其他平台</div>
               <div class="content">
                   <p v-for="item1 in item.platform_list" :key="item1.id" @click="handleClick(item1.url)">{{item1.title}}</p>
                   
               </div>
           </div>
        </div>
        <div class="right">
            <img src="../../assets/zxt.png" style="width:90px;height:90px" alt="">
            <img src="../../assets/logo.png" style="width:77px;height:86.6px;margin-left:40px" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item:{}
        };
    },
    created() {

    },
    mounted() {
        this.handelFooter()
    },
    methods: {
       async handelFooter(){
            const res=await this.request('/conf/index-footer/1')
            if(res.status==200){
                this.item=res.data
            }
        },
        handleClick(url){
            console.log(url)
            window.open(url)
        }
    }
};
</script>

<style scoped lang='less'>
.footer{
    width: 100%;
    height: 200px;
    background: #fff;
    display: flex;
    padding: 0 20%;
    justify-content: space-between;
    // align-items:center;
    .left{
        display: flex;
        width: 78%;
        >div{
           width: 30%; 
           margin-top: 40px;
           .title{
               color: #000;
               font-size: 18px;
           }
           .content{
               width: 100%;
               margin-top: 20px;
               font-size: 14px;
               color: #666;
               line-height: 2em;
               p{
                   margin-bottom: 0;
               }
           }
        }
        .address{
            padding-right: 45px;
        }
        .fuwu{
            padding: 0 45px;
        }
        .pingtai{
            padding-left: 45px;
            .content{
                padding-right:0;
            }
        }
        .bian{
            width: 0;
            height: 100px;
            margin:40px 0 0 0;
            border-right: 1px solid #e4e4e4;
        }
        
    }
    .right{
        width: 22%;
        height: 100%;
        display: flex;
        align-items: center;


    }
}
@media (max-width:1919px){
    .footer .left  {
        .address{
            padding-right: 33px;
        }
        .fuwu{
            padding: 0 33px;
        }
        .pingtai{
            padding-left: 33px;}
    }
}
@media (max-width:1679px){
     .footer .left  {
        .address{
            padding-right: 28px;
        }
        .fuwu{
            padding: 0 28px;
        }
        .pingtai{
            padding-left: 28px;}
    }
}
@media (max-width:1599px){
   .footer .left  {
        .address{
            padding-right: 16px;
        }
        .fuwu{
            padding: 0 16px;
        }
        .pingtai{
            padding-left: 16px;}
    }
}
@media (max-width:1439px){
   .footer .left  {
        .address{
            padding-right: 14px;
        }
        .fuwu{
            padding: 0 14px;
        }
        .pingtai{
            padding-left: 14px;}
    }
}
@media (max-width:1399px){
    .footer{
        padding: 0 19%;
    }
      .footer .left  {
        .address{
            padding-right: 14px;
        }
        .fuwu{
            padding: 0 14px;
        }
        .pingtai{
            padding-left: 14px;}
    }
}
@media (max-width:1365px){
     .footer{
        padding: 0 19%;
    }
      .footer .left  {
        .address{
            padding-right: 14px;
        }
        .fuwu{
            padding: 0 14px;
        }
        .pingtai{
            padding-left: 14px;}
    }
}
</style>
