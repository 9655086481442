<template>
	<div id="app">
		<keep-alive>
		  <router-view v-if="$route.meta.keepAlive">
		 <!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
		   </router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive">
		<!-- 这里是不被缓存的视图组件，比如 page3 -->
		</router-view>
	</div>
</template>
<script>
	import Footer from './views/components/footer.vue'
	import MenuList from './views/components/menuList.vue'
	export default {
		data() {
			return {

			}
		},
		components: {
			Footer,
			MenuList
		},
		    mounted() {


		}
	}
</script>

<style lang="less" scoped>
	html,
	body {
		width: 100% !important;
		height: calc(100% - 90px) !important;
		padding: 0;
		margin: 0;
	}

	#app {
		width: 100%;
		-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
-khtml-user-select: none;
user-select: none;
	}
</style>
